$main-width: 1200px;
$main-padding: 50px;

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block;
}

body {
    line-height: 1;
}

ol,ul {
    list-style: none;
}

blockquote,q {
    quotes: none;
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    -webkit-text-size-adjust: none
}

/* Box Model */

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

/* Container */

body {
    min-width: auto;
}

.container {
    max-width: 60em;
    margin: auto;
    padding: 10px;
}

/* Modifiers */

.container.small {
    width: $main-width - 300px;
}

.container.big {
    width: 100%;
    max-width: $main-width + 300px;
    min-width: $main-width;
}

/* Grid */

/*   Cells */

.\31 2u { width: 100% }
.\31 1u { width: 91.6666666667% }
.\31 0u { width: 83.3333333333% }
.\39 u { width: 75% }
.\38 u { width: 66.6666666667% }
.\37 u { width: 58.3333333333% }
.\36 u { width: 50% }
.\35 u { width: 41.6666666667% }
.\34 u { width: 33.3333333333% }
.\33 u { width: 25% }
.\32 u { width: 16.6666666667% }
.\31 u { width: 8.3333333333% }
.\-11u { margin-left: 91.6666666667% }
.\-10u { margin-left: 83.3333333333% }
.\-9u { margin-left: 75% }
.\-8u { margin-left: 66.6666666667% }
.\-7u { margin-left: 58.3333333333% }
.\-6u { margin-left: 50% }
.\-5u { margin-left: 41.6666666667% }
.\-4u { margin-left: 33.3333333333% }
.\-3u { margin-left: 25% }
.\-2u { margin-left: 16.6666666667% }
.\-1u { margin-left: 8.3333333333% }

.row > * {
    padding: $main-padding 0 0 $main-padding;
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.row + .row > * {
    padding-top: $main-padding;
}

.row {
    margin-left: -$main-padding;
}

/* Rows */

.row:after {
    content: '';
    display: block;
    clear: both;
    height: 0;
}

.row:first-child > * {
    padding-top: 0;
}

.row > * {
    padding-top: 0;
}

/* Modifiers */
/*   Flush */

.row.flush {
    margin-left: 0;
}

.row.flush > * {
    padding: 0 !important;
}

/* Quarter */

.row.quarter > * {
    padding: $main-padding/4 0 0 $main-padding/4;
}

.row.quarter + .row.quarter > * {
    padding-top: $main-padding/4;
}

.row.quarter {
    margin-left: -$main-padding/4;
}

/* Half */

.row.half > * {
    padding: $main-padding/2 0 0 $main-padding/2;
}

.row.half + .row.half > * {
    padding-top: $main-padding/2;
}

.row.half {
    margin-left: -$main-padding/2;
}

/* One and (a) Half */

.row.oneandhalf > * {
    padding: $main-padding*3/2 0 0 $main-padding*3/2;
}

.row.oneandhalf + .row.oneandhalf > * {
    padding-top: $main-padding*3/2;
}

.row.oneandhalf {
    margin-left: -$main-padding*3/2;
}

/* Double */

.row.double > * {
    padding: $main-padding*2 0 0 $main-padding*2;
}

.row.double + .row.double > * {
    padding-top: $main-padding*2;
}

.row.double {
    margin-left: -$main-padding*2;
}
